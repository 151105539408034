<template>
  <div>
    <h3 class="section-title">{{ $t("payment.section3") }}</h3>
    <section class="section">
      <div class="agreed-section">
        <input
          class="form-check-input"
          type="checkbox"
          v-model="agreed"
          :disabled="agreed"
        />
        <p>{{ $t("payment.agreed") }}<span>*</span></p>
      </div>
      <div v-if="agreed && (segwitAddress || legacyAddress)" class="qr-section">
        <div class="qr-row">
          <payment-qr
            v-if="segwitAddress"
            :link="segwitAddress"
            :title="$t('payment.segwit')"
          />
          <payment-qr
            v-if="legacyAddress"
            :link="legacyAddress"
            :title="$t('payment.legacy')"
          />
        </div>
        <!-- <button
          v-if="legacyAddress && !legacyShowed"
          @click="showLegacy"
          class="outlined-button qr-button"
        >
          {{ $t("payment.walletNotSupported") }}
        </button> -->
      </div>
    </section>
  </div>
</template>

<script>
export default {
  components: {
    PaymentQr: () => import("./payment-qr.vue"),
  },

  props: {
    segwitAddress: [String, null],
    legacyAddress: [String, null],
  },

  data() {
    return {
      agreed: false,
    };
  },
};
</script>

<style scoped src="../style.css"></style>
